import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react';
import TreeTableItem from 'portaltransparencia-common/components/treetable/TreeTableItem.react';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export default class TableItem extends Component {
  static propTypes = {
    onClickButton: PropTypes.func,
    onClickButtonGraph: PropTypes.func.isRequired,
    item: PropTypes.object,
    expand: PropTypes.func.isRequired,
    collapse: PropTypes.func.isRequired,
    mostrarValorLancado: PropTypes.bool
  };

  onClickItem = () => {
    const { item, expand, collapse } = this.props;

    if (item.get('open')) {
      collapse(item.get('receita'), item.get('nivel'));
    } else {
      expand(item.get('receita'), item.get('nivel'));
    }
  };

  render = () => {
    const {
      item,
      onClickButton,
      onClickButtonGraph,
      mostrarValorLancado
    } = this.props;

    if (!item.get('visible')) {
      return null;
    }

    const isPrimeiroNivel = item.get('nivel') == 1;
    let icon = isPrimeiroNivel ? 'fa fa-bar-chart' : 'fa fa-pie-chart';

    return (
      <TreeTableItem
        key={item.get('KEYITEM')}
        data={item}
        dataLevel={item.get('receita')}
        father={item.get('aceitaMovimentacao') == 'N'}
        level={item.get('nivel')}
        showButton={item.get('aceitaMovimentacao') == 'S'}
        onClickButton={onClickButton}
        visible={item.get('visible')}
        onClickButtonGraph={onClickButtonGraph}
        showButtonGraph={isPrimeiroNivel}
        iconGraph={icon}
      >
        <td style={{ cursor: 'pointer' }} onClick={this.onClickItem}>
          {item.get('receita')}
        </td>
        <td>{item.get('descricao')}</td>
        <td className="right">
          <NumberFormatter value={item.get('valorOrcado')} />
        </td>
        <td className="right">
          <NumberFormatter value={item.get('valorAtualizado')} />
        </td>
        {mostrarValorLancado && (
          <td className="right">
            <NumberFormatter value={item.get('valorLancado')} />
          </td>
        )}
        <td className="right">
          <NumberFormatter value={item.get('valorArrecadado')} />
        </td>
      </TreeTableItem>
    );
  };
}
