import DatePicker from 'portaltransparencia-common/components/DatePicker.react';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';

import FilterButtons from '../components/FilterButtons.react';
import { collapse } from './Actions';

export default class FormReceitaFiltro extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    montaFiltro: PropTypes.func.isRequired,
    resetCrud: PropTypes.func.isRequired,
    filtroReceitas: PropTypes.string.isRequired,
    filtroInicial: PropTypes.func.isRequired,
    onConsulta: PropTypes.func.isRequired,
    onResetReceitas: PropTypes.func.isRequired
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroReceitas', event);
  };

  onResetFiltro = () => {
    const { resetCrud, onResetReceitas } = this.props;
    resetCrud('filtroReceitas');
    onResetReceitas();
  };

  onConsulta = () => {
    const { onConsulta } = this.props;

    onConsulta();
  };

  render = () => {
    const { filtros } = this.props;

    return (
      <Panel title="Consultar" mobileClose isForm ref="panelFiltroReceitas">
        <form>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataInicial"
                name="dataInicial"
                ref="dataInicial"
                label="Data Inicial"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataInicial')}
              />
            </Col>
            <Col xs={6} sm={4} md={3}>
              <DatePicker
                id="dataFinal"
                name="dataFinal"
                ref="dataFinal"
                label="Data Final"
                onChange={this.onChangeFiltro}
                value={filtros.get('dataFinal')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} sm={4} md={3}>
              <Input
                id="receita"
                name="receita"
                ref="receita"
                placeholder="Digite aqui a receita"
                label="Receita"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('receita')}
              />
            </Col>
            <Col xs={12} sm={8} md={8}>
              <Input
                id="descricaoReceita"
                name="descricaoReceita"
                ref="descricaoReceita"
                placeholder="Digite aqui a Descrição da Receita"
                label="Descrição Receita"
                type="text"
                onChange={this.onChangeFiltro}
                value={filtros.get('descricaoReceita')}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={4} md={6}>
              <FilterButtons
                idBtnConsulta="btnFiltrar"
                idBtnReset="btnResetFilter"
                on
                onConsulta={this.onConsulta}
                onReset={this.onResetFiltro}
              />
            </Col>
          </Row>
        </form>
      </Panel>
    );
  };
}
