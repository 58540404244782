import * as types from './Types.js';

export function findLiquidacoesPagar(filter) {
  return ({ fetch }) => ({
    type: types.PAGAR_LIST,
    payload: {
      promise: fetch(`/api/liquidacoes/pagar?${filter}`)
    }
  });
}

export function findTotalLiquidacoesPagar(filter) {
  return ({ fetch }) => ({
    type: types.TOTAL_PAGAR,
    payload: {
      promise: fetch(`/api/liquidacoes/pagar/total?${filter}`)
    }
  });
}

export function findLiquidacoesPagas(filter) {
  return ({ fetch }) => ({
    type: types.PAGAS_LIST,
    payload: {
      promise: fetch(`/api/liquidacoes/pagas?${filter}`)
    }
  });
}

export function findTotalLiquidacoesPagas(filter) {
  return ({ fetch }) => ({
    type: types.TOTAL_PAGAS,
    payload: {
      promise: fetch(`/api/liquidacoes/pagas/total?${filter}`)
    }
  });
}

export function findOrgao() {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAORGAO,
    payload: {
      promise: fetch('/api/programatica/orgao')
    }
  });
}

export function findUnidade(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAUNIDADE,
    payload: fetch(`/api/programatica/unidade?${filtro}`)
  });
}

export function findFuncao(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAFUNCAO,
    payload: {
      promise: fetch(`/api/programatica/funcao?${filtro}`)
    }
  });
}

export function findSubFuncao(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICASUBFUNCAO,
    payload: {
      promise: fetch(`/api/programatica/subFuncao?${filtro}`)
    }
  });
}

export function findPrograma(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAPROGRAMA,
    payload: {
      promise: fetch(`/api/programatica/programa?${filtro}`)
    }
  });
}

export function findProjeto(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAPROJETO,
    payload: {
      promise: fetch(`/api/programatica/projeto?${filtro}`)
    }
  });
}

export function findElemento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAELEMENTO,
    payload: {
      promise: fetch(`/api/programatica/elemento?${filtro}`)
    }
  });
}

export function findDesdobramento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICADESDOBRAMENTO,
    payload: {
      promise: fetch(`/api/programatica/desdobramento?${filtro}`)
    }
  });
}

export function findSubDesdobramento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICASUBDESDOBRAMENTO,
    payload: {
      promise: fetch(`/api/programatica/subDesdobramento?${filtro}`)
    }
  });
}

export function findFonteRecurso(filter) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAFONTERECURSO,
    payload: {
      promise: fetch(`/api/programatica/fonteRecurso?${filter}`)
    }
  });
}
