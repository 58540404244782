import * as types from './Types.js';

import { endLoad, startLoad } from 'portaltransparencia-common/crud/Core.js';

import { fromJS } from 'immutable';

const initialState = fromJS({
  empenhos: {},
  totais: {},
  empenhoDetalhe: {},
  itens: [],
  anulacoes: [],
  liquidacoes: [],
  retencoes: [],
  liquidacoesDocumentos: [],
  pagamentos: [],
  movimentacao: {},
  anexos: [],
  filtroListagem: '',
  filtroProgramatica: {},
  itemDetalhe: {},
  emLiquidacao: [],
  links: []
});

function empenhosReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_EMPENHO: {
      return state.clear().merge(initialState);
    }

    case types.FILTROLISTAGEM_EMPENHO: {
      return state.set('filtroListagem', action.payload);
    }

    case types.EMPENHOS_START:
    case types.EMPENHOSTOTAIS_START:
    case types.EMPENHODETALHE_START:
    case types.EMPENHODETALHEITENS_START:
    case types.EMPENHODETALHEANULACOES_START:
    case types.EMPENHODETALHELIQUIDACOES_START:
    case types.EMPENHODETALHERETENCOES_START:
    case types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_START:
    case types.EMPENHODETALHEPAGAMENTOS_START:
    case types.EMPENHODETALHEMOVIMENTACAO_START:
    case types.EMPENHODETALHEANEXOS_START:
    case types.PROGRAMATICAORGAO_START:
    case types.PROGRAMATICAUNIDADE_START:
    case types.PROGRAMATICAFUNCAO_START:
    case types.PROGRAMATICASUBFUNCAO_START:
    case types.PROGRAMATICAPROGRAMA_START:
    case types.PROGRAMATICAPROJETO_START:
    case types.PROGRAMATICAELEMENTO_START:
    case types.PROGRAMATICADESDOBRAMENTO_START:
    case types.PROGRAMATICASUBDESDOBRAMENTO_START:
    case types.PROGRAMATICAFONTERECURSO_START:
    case types.EMPENHODETALHEITEM_START:
    case types.EMPENHO_DETALHE_EM_LIQUIDACAO_START:
    case types.NOTAEMPENHO_START:
    case types.EMPENHODETALHELINKS_START:
    case types.EMPENHOS_SEM_EXERCICIO_START: {
      return startLoad(state);
    }

    case types.EMPENHOS_ERROR:
    case types.EMPENHOSTOTAIS_ERROR:
    case types.EMPENHODETALHE_ERROR:
    case types.EMPENHODETALHEITENS_ERROR:
    case types.EMPENHODETALHEANULACOES_ERROR:
    case types.EMPENHODETALHELIQUIDACOES_ERROR:
    case types.EMPENHODETALHERETENCOES_ERROR:
    case types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_ERROR:
    case types.EMPENHODETALHEPAGAMENTOS_ERROR:
    case types.EMPENHODETALHEMOVIMENTACAO_ERROR:
    case types.EMPENHODETALHEANEXOS_ERROR:
    case types.PROGRAMATICAORGAO_ERROR:
    case types.PROGRAMATICAUNIDADE_ERROR:
    case types.PROGRAMATICAFUNCAO_ERROR:
    case types.PROGRAMATICASUBFUNCAO_ERROR:
    case types.PROGRAMATICAPROGRAMA_ERROR:
    case types.PROGRAMATICAPROJETO_ERROR:
    case types.PROGRAMATICAELEMENTO_ERROR:
    case types.PROGRAMATICADESDOBRAMENTO_ERROR:
    case types.PROGRAMATICASUBDESDOBRAMENTO_ERROR:
    case types.PROGRAMATICAFONTERECURSO_ERROR:
    case types.EMPENHODETALHEITEM_ERROR:
    case types.EMPENHO_DETALHE_EM_LIQUIDACAO_ERROR:
    case types.NOTAEMPENHO_ERROR:
    case types.EMPENHODETALHELINKS_ERROR:
    case types.EMPENHOS_SEM_EXERCICIO_ERROR: {
      return endLoad(state);
    }

    case types.EMPENHOS_SUCCESS:
    case types.EMPENHOS_SEM_EXERCICIO_SUCCESS: {
      return endLoad(state.set('empenhos', fromJS(action.payload)));
    }

    case types.EMPENHOSTOTAIS_SUCCESS: {
      return endLoad(state.set('totais', fromJS(action.payload)));
    }

    case types.EMPENHODETALHE_SUCCESS: {
      return endLoad(state.set('empenhoDetalhe', fromJS(action.payload)));
    }

    case types.EMPENHODETALHEITENS_SUCCESS: {
      return endLoad(state.set('itens', fromJS(action.payload)));
    }

    case types.EMPENHODETALHEANULACOES_SUCCESS: {
      return endLoad(state.set('anulacoes', fromJS(action.payload)));
    }

    case types.EMPENHODETALHELIQUIDACOES_SUCCESS: {
      return endLoad(state.set('liquidacoes', fromJS(action.payload)));
    }

    case types.EMPENHODETALHELIQUIDACOES_RESET_SUCCESS: {
      return endLoad(state.set('liquidacoes', fromJS([])));
    }

    case types.EMPENHODETALHELIQUIDACOES_MULTIPLAS_SUCCESS: {
      return endLoad(
        state.set(
          'liquidacoes',
          state.get('liquidacoes').concat(fromJS(action.payload))
        )
      );
    }

    case types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO_SUCCESS: {
      return endLoad(
        state.set(
          'liquidacoesDocumentos',
          state.get('liquidacoesDocumentos').concat(fromJS(action.payload))
        )
      );
    }

    case types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET_SUCCESS: {
      return endLoad(state.set('liquidacoesDocumentos', fromJS([])));
    }

    case types.EMPENHODETALHERETENCOES_SUCCESS: {
      return endLoad(state.set('retencoes', fromJS(action.payload)));
    }

    case types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_SUCCESS: {
      return endLoad(
        state.set('liquidacoesDocumentos', fromJS(action.payload))
      );
    }

    case types.EMPENHODETALHEANEXOS_SUCCESS: {
      return endLoad(state.set('anexos', fromJS(action.payload)));
    }

    case types.EMPENHODETALHEPAGAMENTOS_SUCCESS: {
      return endLoad(state.set('pagamentos', fromJS(action.payload)));
    }

    case types.EMPENHODETALHEMOVIMENTACAO_SUCCESS: {
      return endLoad(state.set('movimentacao', fromJS(action.payload)));
    }

    case types.PROGRAMATICAORGAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'orgao'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAUNIDADE_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'unidade'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAFUNCAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'funcao'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICASUBFUNCAO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'subFuncao'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAPROGRAMA_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'programa'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAPROJETO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'projeto'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICAELEMENTO_SUCCESS: {
      return endLoad(
        state.setIn(['filtroProgramatica', 'elemento'], fromJS(action.payload))
      );
    }
    case types.PROGRAMATICADESDOBRAMENTO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'desdobramento'],
          fromJS(action.payload)
        )
      );
    }
    case types.PROGRAMATICASUBDESDOBRAMENTO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'subDesdobramento'],
          fromJS(action.payload)
        )
      );
    }
    case types.PROGRAMATICAFONTERECURSO_SUCCESS: {
      return endLoad(
        state.setIn(
          ['filtroProgramatica', 'fonteRecurso'],
          fromJS(action.payload)
        )
      );
    }
    case types.EMPENHODETALHEITEM_SUCCESS: {
      return endLoad(state.set('itemDetalhe', fromJS(action.payload)));
    }
    case types.EMPENHO_DETALHE_EM_LIQUIDACAO_SUCCESS: {
      return endLoad(state.set('emLiquidacao', fromJS(action.payload)));
    }
    case types.NOTAEMPENHO_SUCCESS: {
      return endLoad(state.set('nota-empenho', fromJS(action.payload)));
    }

    case types.EMPENHODETALHELINKS_SUCCESS: {
      return endLoad(state.set('links', fromJS(action.payload)));
    }
  }

  return state;
}

export default empenhosReducer;
