import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';
import { addPagination } from 'portaltransparencia-common/libs/AddFilter';
import { connect } from 'react-redux';
import * as actions from './Actions.js';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import { getAllValuesFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils.js';

export class EmpenhoDetalheMovimentacao extends Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object.isRequired,
    movimentacao: PropTypes.object.isRequired,
    page: PropTypes.object.isRequired,
    findEmpenhoMovimentacao: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { page } = this.props;
    const initialPage = page.set('number', 0);
    page.set('size', 20);

    this.searchWithPage(initialPage);
  }

  renderItens = page => {
    let key =
      page.get('tipoLancamento') +
      '_' +
      page.get('noLiquidacao') +
      '_' +
      page.get('exercicioLiquidacao') +
      '_' +
      page.get('noPagamento') +
      '_' +
      page.get('exercicioPagamento') +
      '_' +
      page.get('nroDocumento');

    return (
      <tr key={key} data={page}>
        <td>{DateUtils.formatDateTimeShort(page.get('data'))}</td>
        <td>{page.get('descricaoTipoLancamento')}</td>
        <td>{page.get('nroDocumento')}</td>
        <td className="right">
          <NumberFormatter value={page.get('valor')} />
        </td>
        <td className="right">
          <NumberFormatter value={page.get('valorALiquidar')} />
        </td>
        <td className="right">
          <NumberFormatter value={page.get('valorAPagar')} />
        </td>
      </tr>
    );
  };

  searchWithPage = page => {
    const { findEmpenhoMovimentacao, location } = this.props;
    const params = getAllValuesFromUrlSearchParams(location);
    let filtro = `entidade=${params.entidade}&exercicio=${params.exercicio}&empenho=${params.empenho}`;
    let pfiltro = addPagination(page, filtro);

    findEmpenhoMovimentacao(pfiltro);
  };

  render() {
    const { page } = this.props;
    return (
      <div>
        <div>
          {page && page.get('content') && (
            <table className="panel-table striped fancy">
              <TableHeader empty={!page.get('content')}>
                <tr>
                  <TableHeaderItem
                    field="data"
                    label="Data"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="descricaoTipoLancamento"
                    label="Descrição"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="nroDocumento"
                    label="Nº Documento"
                    page={page}
                    searchWithPage={this.searchWithPage}
                  />
                  <TableHeaderItem
                    field="valor"
                    label="Valor"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorALiquidar"
                    label="Valor a Liquidar"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                  <TableHeaderItem
                    field="valorAPagar"
                    label="Valor a Pagar"
                    page={page}
                    searchWithPage={this.searchWithPage}
                    alignment="right"
                  />
                </tr>
              </TableHeader>
              <tbody>{page.get('content').map(this.renderItens)}</tbody>
            </table>
          )}
        </div>
        <SearchPagination page={page} searchWithPage={this.searchWithPage} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    page: state.empenhos.get('movimentacao'),
    movimentacao: state.empenhos.get('movimentacao')
  };
}

const allActions = {
  ...actions
};

export default connect(mapStateToProps, allActions)(EmpenhoDetalheMovimentacao);
