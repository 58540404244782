import Input from 'portaltransparencia-common/components/EloInput.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';
import LinkToEmpenho from '../empenhos/LinkToEmpenho.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import * as actions from './Actions.js';
import { Map, fromJS } from 'immutable';
import configuration from 'portaltransparencia-common/config/Config.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import AutoComplete from 'portaltransparencia-common/components/AutoComplete.react.js';

export class FormRestosAPagar extends Component {
  static propTypes = {
    pageProcessados: PropTypes.object.isRequired,
    pageNaoProcessados: PropTypes.object.isRequired,
    findRestosAPagarProcessados: PropTypes.func.isRequired,
    findRestosAPagarNaoProcessados: PropTypes.func.isRequired,
    filtros: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,
    tipoPesquisa: PropTypes.object.isRequired,
    addErrorNotification: PropTypes.func.isRequired,
    errors: PropTypes.object,
    findOrgao: PropTypes.func.isRequired,
    findUnidade: PropTypes.func.isRequired,
    findFuncao: PropTypes.func.isRequired,
    findSubFuncao: PropTypes.func.isRequired,
    findPrograma: PropTypes.func.isRequired,
    findProjeto: PropTypes.func.isRequired,
    findElemento: PropTypes.func.isRequired,
    findDesdobramento: PropTypes.func.isRequired,
    findSubDesdobramento: PropTypes.func.isRequired,
    findFonteRecurso: PropTypes.func.isRequired,
    isIntegradoOxy: PropTypes.bool
  };

  componentDidMount() {
    this.carregarRestosAPagarProcessado();
    this.carregarRestosAPagarNaoProcessado();
  }

  carregarRestosAPagarProcessado = () => {
    const { pageProcessados } = this.props;
    const initialPage = pageProcessados.set('number', 0);

    this.searchWithPageProcessados(initialPage);
  };

  carregarRestosAPagarNaoProcessado = () => {
    const { pageNaoProcessados } = this.props;
    const initialPage = pageNaoProcessados.set('number', 0);

    this.searchWithPageNaoProcessados(initialPage);
  };

  consultarRestosAPagar = event => {
    this.carregarRestosAPagarProcessado();
    this.carregarRestosAPagarNaoProcessado();

    event && event.preventDefault();
    this.refs.panelFiltroRestosAPagar &&
      this.refs.panelFiltroRestosAPagar.collapse();
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroRestosAPagar', event);
  };

  getFiltroProgramatica = filtroRestosAPagar => {
    const { filtros } = this.props;
    const orgao = filtros.get('orgao');
    const unidade = filtros.get('unidade');
    const funcao = filtros.get('funcao');
    const subFuncao = filtros.get('subFuncao');
    const programa = filtros.get('programa');
    const projeto = filtros.get('projeto');
    const elemento = filtros.get('elemento');
    const desdobramento = filtros.get('desdobramento');
    const subDesdobramento = filtros.get('subDesdobramento');
    const fonteRecurso = filtros.get('fonteRecurso');

    let filter = '';
    let prefix = '';
    if (filtroRestosAPagar) {
      filter = filtroRestosAPagar;
      prefix = 'programatica.';
    }
    if (orgao) {
      filter = addFilterUrl(filter, prefix + 'orgao=' + orgao.get('codigo'));
      if (unidade) {
        filter = addFilterUrl(
          filter,
          prefix + 'unidade=' + unidade.get('codigo')
        );
      }
    }
    if (funcao) {
      filter = addFilterUrl(filter, prefix + 'funcao=' + funcao.get('codigo'));
    }
    if (subFuncao) {
      filter = addFilterUrl(
        filter,
        prefix + 'subFuncao=' + subFuncao.get('codigo')
      );
    }
    if (programa) {
      filter = addFilterUrl(
        filter,
        prefix + 'programa=' + programa.get('codigo')
      );
    }
    if (projeto) {
      filter = addFilterUrl(
        filter,
        prefix + 'projeto=' + projeto.get('codigo')
      );
    }
    if (elemento) {
      this.exibeDesdobramento = true;
      this.exibeSubDesdobramento = true;

      filter = addFilterUrl(
        filter,
        prefix + 'elemento=' + elemento.get('codigo')
      );
    }
    if (desdobramento) {
      filter = addFilterUrl(
        filter,
        prefix + 'desdobramento=' + desdobramento.get('codigo')
      );
    }
    if (subDesdobramento) {
      filter = addFilterUrl(
        filter,
        prefix + 'subDesdobramento=' + subDesdobramento.get('codigo')
      );
    }
    if (fonteRecurso) {
      filter = addFilterUrl(
        filter,
        'fonteRecurso=' + fonteRecurso.get('codigo')
      );
    }
    return filter;
  };

  findOrgao = () => {
    const { findOrgao } = this.props;
    return findOrgao(this.getFiltroProgramatica());
  };

  findUnidade = () => {
    const { findUnidade } = this.props;
    return findUnidade(this.getFiltroProgramatica());
  };

  findFuncao = () => {
    const { findFuncao } = this.props;
    return findFuncao(this.getFiltroProgramatica());
  };

  findSubFuncao = () => {
    const { findSubFuncao } = this.props;
    return findSubFuncao(this.getFiltroProgramatica());
  };

  findPrograma = () => {
    const { findPrograma } = this.props;
    return findPrograma(this.getFiltroProgramatica());
  };

  findProjeto = () => {
    const { findProjeto } = this.props;
    return findProjeto(this.getFiltroProgramatica());
  };

  findElemento = () => {
    const { findElemento } = this.props;
    return findElemento(this.getFiltroProgramatica());
  };

  findDesdobramento = () => {
    const { findDesdobramento } = this.props;
    return findDesdobramento(this.getFiltroProgramatica());
  };

  findSubDesdobramento = () => {
    const { findSubDesdobramento } = this.props;
    return findSubDesdobramento(this.getFiltroProgramatica());
  };

  findFonteRecurso = () => {
    const { findFonteRecurso } = this.props;
    return findFonteRecurso(this.getFiltroProgramatica());
  };

  onChangeFiltroProgramatica = event => {
    const { onChange } = this.props;
    new Promise(resolve => {
      onChange('filtroRestosAPagar', event);
      resolve();
    }).then(() => {
      switch (event.name) {
        case 'orgao': {
          this.findOrgao();
        }
        case 'unidade': {
          this.findUnidade();
        }
        case 'funcao': {
          this.findFuncao();
        }
        case 'subFuncao': {
          this.findSubFuncao();
        }
        case 'programa': {
          this.findPrograma();
        }
        case 'projeto': {
          this.findProjeto();
        }
        case 'elemento': {
          this.findElemento();
        }
        case 'desdobramento': {
          this.findDesdobramento();
        }
        case 'subDesdobramento': {
          this.findSubDesdobramento();
        }
        case 'fonteRecurso': {
          this.findFonteRecurso();
        }
      }
    });
  };

  onChangeFiltroElemento = event => {
    const { changeFieldValue } = this.props;

    if (event.value) {
      this.exibeDesdobramento = true;
      this.exibeSubDesdobramento = true;
    } else {
      this.exibeDesdobramento = false;
      this.exibeSubDesdobramento = false;

      changeFieldValue('filtroRestosAPagar', 'desdobramento', '');
      changeFieldValue('filtroRestosAPagar', 'subDesdobramento', '');
    }

    this.onChangeFiltro(event);
  };

  onChangeFiltroDesdobramento = event => {
    const { changeFieldValue } = this.props;

    changeFieldValue('filtroRestosAPagar', 'subDesdobramento', '');

    this.onChangeFiltro(event);
  };

  renderValue = option => {
    return <span>{option.codigo + ' - ' + option.descricao}</span>;
  };

  onResetFiltro = () => {
    const { resetCrud } = this.props;

    resetCrud('filtroRestosAPagar');
  };

  renderCombo = item => {
    return (
      <option key={item} value={item}>
        {item}
      </option>
    );
  };

  somenteNumeros(valor) {
    return valor.replace(/[^0-9]/g, '');
  }

  montarFiltro = function (page, tab = '') {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const { filtros } = this.props;

    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    if (
      (tab == 'Processados' &&
        filtros.get('tipoPesquisa') == 'Não Processados') ||
      (tab == 'NaoProcessados' && filtros.get('tipoPesquisa') == 'Processados')
    ) {
      filter = addFilterUrl(filter, 'empenho=0');
    } else {
      if (tab != '') {
        filter = addFilterUrl(filter, 'tipoPesquisa=' + tab);
      }

      if (this.refs.cnpj && this.refs.cnpj.getValue()) {
        filter = addFilterUrl(
          filter,
          'cnpjCpf=' + this.somenteNumeros(this.refs.cnpj.getValue().trim())
        );
      }

      if (this.refs.nome && this.refs.nome.getValue()) {
        filter = addFilterUrl(
          filter,
          'nome=' + this.refs.nome.getValue().trim()
        );
      }

      if (this.refs.empenho && this.refs.empenho.getValue()) {
        filter = addFilterUrl(
          filter,
          'empenho=' + this.somenteNumeros(this.refs.empenho.getValue())
        );
      }

      if (this.refs.anoempenho && this.refs.anoempenho.getValue()) {
        filter = addFilterUrl(
          filter,
          'anoempenho=' + this.somenteNumeros(this.refs.anoempenho.getValue())
        );
      }

      filter = this.getFiltroProgramatica(filter);
    }

    let validValueInicial = DateUtils.transform(
      exercicio.id + '-01-01',
      'yy-mm-dd',
      'yy-mm-dd'
    );
    filter = addFilterUrl(filter, 'dataInicial=' + validValueInicial);

    let validValueFinal = DateUtils.transform(
      exercicio.id + '-12-31',
      'yy-mm-dd',
      'yy-mm-dd'
    );
    filter = addFilterUrl(filter, 'dataFinal=' + validValueFinal);

    console.log('Page: ', page.get('sort'));

    return addPagination(page, filter);
  };

  searchWithPageProcessados = pageProcessados => {
    const { findRestosAPagarProcessados } = this.props;
    findRestosAPagarProcessados(
      this.montarFiltro(pageProcessados, 'Processados')
    );
  };

  searchWithPageNaoProcessados = pageNaoProcessados => {
    const { findRestosAPagarNaoProcessados } = this.props;
    findRestosAPagarNaoProcessados(
      this.montarFiltro(pageNaoProcessados, 'NaoProcessados')
    );
  };

  onViewMore = data => {
    const entidade = data.get('entidade');
    const exercicio = data.get('anoempenho');
    const empenho = data.get('empenho');

    this.props.history.push(
      `/empenhos/detalhe?search=id.entidade==${entidade}&entidade=${entidade}&exercicio=${exercicio}&empenho=${empenho}`
    );
  };

  renderRestosProcessados = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('empenho') + '_' + data.get('anoempenho')}
        data={data}
        onMore={this.onViewMore}
      >
        <td>
          <LinkToEmpenho
            entidade={data.get('entidade')}
            exercicio={data.get('anoempenho')}
            empenho={data.get('empenho')}
          />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataEmissao'))}</td>
        <td>{data.get('descricaoFonte')}</td>
        <td>{data.get('nome')}</td>
        <td className="right">
          <NumberFormatter value={data.get('proc')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('canceladoProc')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('pagoProc')} />
        </td>
      </TableActions>
    );
  };

  renderRestosNaoProcessados = data => {
    return (
      <TableActions
        ref="row"
        key={data.get('empenho') + '_' + data.get('anoempenho')}
        data={data}
        onMore={this.onViewMore}
      >
        <td>
          <LinkToEmpenho
            entidade={data.get('entidade')}
            exercicio={data.get('anoempenho')}
            empenho={data.get('empenho')}
          />
        </td>
        <td>{DateUtils.formatDateTimeShort(data.get('dataEmissao'))}</td>
        <td>{data.get('descricaoFonte')}</td>
        <td>{data.get('nome')}</td>
        <td className="right">
          <NumberFormatter value={data.get('aproc')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('liquidado')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('canceladoAProc')} />
        </td>
        <td className="right">
          <NumberFormatter value={data.get('pagoAProc')} />
        </td>
      </TableActions>
    );
  };

  onExporta = (exportType, openTab, windowParams) => {
    const {
      pageProcessados,
      pageNaoProcessados,
      addErrorNotification
    } = this.props;
    const { apiURL } = configuration;

    let page = null;
    let tipo = '';

    if (this.refs.tab) {
      switch (this.refs.tab.getActiveTabName()) {
        case 'tabProcessados':
          page = pageProcessados;
          tipo = 'Processados';
          break;
        case 'tabNaoProcessados':
          page = pageNaoProcessados;
          tipo = 'NaoProcessados';
          break;
      }
    }

    if (page && page.get('content').size > 0) {
      let filtro = this.montarFiltro(page, tipo) + '&exportType=' + exportType;
      window.open(
        `${apiURL}/empenhos/restos-a-pagar/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      pageProcessados,
      pageNaoProcessados,
      filtros,
      location,
      tipoPesquisa,
      errors,
      isIntegradoOxy
    } = this.props;

    return (
      <section>
        <PageHeader location={location} />

        <NotasExplicativas pathname={this.props.location.pathname} />

        <Panel
          isForm
          mobileClose
          title="Consulta em Restos a Pagar"
          ref="panelFiltroRestosAPagar"
        >
          <form>
            <Row>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ/CPF do Fornecedor"
                  label="CNPJ/CPF"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nome"
                  name="nome"
                  ref="nome"
                  placeholder="Nome do Fornecedor"
                  label="Nome do Fornecedor"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nome')}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <Input
                  id="empenho"
                  type="number"
                  ref="empenho"
                  name="empenho"
                  placeholder="Número do Empenho"
                  label="Empenho"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('empenho')}
                />
              </Col>
              <Col xs={12} sm={3} md={3}>
                <Input
                  id="anoempenho"
                  type="number"
                  ref="anoempenho"
                  name="anoempenho"
                  placeholder="Ano do Empenho"
                  label="Ano"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('anoempenho')}
                />
              </Col>
              {!isIntegradoOxy && (
                <Col xs={12} sm={5} md={5}>
                  <Input
                    id="tipoPesquisa"
                    name="tipoPesquisa"
                    ref="tipoPesquisa"
                    label="Tipo"
                    type="select"
                    onChange={this.onChangeFiltro}
                    value={filtros.get('tipoPesquisa')}
                    defaultValue={'Todos'}
                  >
                    {tipoPesquisa.map(this.renderCombo)}
                  </Input>
                </Col>
              )}
            </Row>

            <div className="panel-inside-panel mt-xs">
              <Panel
                insidePanel
                title="Especificar Programática"
                collapsed
                isTable
                tableResponsive
              >
                <Row>
                  <Col xs={12} sm={4} md={4} key={filtros.get('orgao')}>
                    <AutoComplete
                      label="Órgão"
                      id="orgao"
                      name="orgao"
                      onLoad={this.findOrgao}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('orgao')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione o Órgão"
                      clearable
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('unidade')}>
                    <AutoComplete
                      label="Unidade"
                      id="unidade"
                      name="unidade"
                      onLoad={this.findUnidade}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('unidade')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione a Unidade"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('funcao')}>
                    <AutoComplete
                      label="Função"
                      id="funcao"
                      name="funcao"
                      onLoad={this.findFuncao}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('funcao')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione a Função"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('subFuncao')}>
                    <AutoComplete
                      label="Sub Função"
                      id="subFuncao"
                      name="subFuncao"
                      onLoad={this.findSubFuncao}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('subFuncao')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione a Sub Função"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('programa')}>
                    <AutoComplete
                      label="Programa"
                      id="programa"
                      name="programa"
                      onLoad={this.findPrograma}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('programa')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione o Programa"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('projeto')}>
                    <AutoComplete
                      label="Projeto"
                      id="projeto"
                      name="projeto"
                      onLoad={this.findProjeto}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('projeto')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione o Projeto"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  <Col xs={12} sm={4} md={4} key={filtros.get('elemento')}>
                    <AutoComplete
                      label="Elemento"
                      id="elemento"
                      name="elemento"
                      onLoad={this.findElemento}
                      onChange={this.onChangeFiltroElemento}
                      value={filtros.get('elemento')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione o Elemento"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                  {this.exibeDesdobramento && (
                    <Col
                      xs={12}
                      sm={4}
                      md={4}
                      key={filtros.get('desdobramento')}
                    >
                      <AutoComplete
                        label="Desdobramento"
                        id="desdobramento"
                        name="desdobramento"
                        onLoad={this.findDesdobramento}
                        onChange={this.onChangeFiltroDesdobramento}
                        value={filtros.get('desdobramento')}
                        errors={errors}
                        valueKey="codigo"
                        labelKey="descricao"
                        placeholder="Selecione o Desdobramento"
                        valueRenderer={this.renderValue}
                      />
                    </Col>
                  )}
                  {this.exibeSubDesdobramento && (
                    <Col
                      xs={12}
                      sm={4}
                      md={4}
                      key={filtros.get('subDesdobramento')}
                    >
                      <AutoComplete
                        label="Sub Desdobramento"
                        id="subDesdobramento"
                        name="subDesdobramento"
                        onLoad={this.findSubDesdobramento}
                        onChange={this.onChangeFiltroProgramatica}
                        value={filtros.get('subDesdobramento')}
                        errors={errors}
                        valueKey="codigo"
                        labelKey="descricao"
                        placeholder="Selecione o Sub Desdobramento"
                        valueRenderer={this.renderValue}
                      />
                    </Col>
                  )}
                  <Col xs={12} sm={4} md={4} key={filtros.get('fonteRecurso')}>
                    <AutoComplete
                      label="Fonte Recurso"
                      id="fonteRecurso"
                      name="fonteRecurso"
                      onLoad={this.findFonteRecurso}
                      onChange={this.onChangeFiltroProgramatica}
                      value={filtros.get('fonteRecurso')}
                      errors={errors}
                      valueKey="codigo"
                      labelKey="descricao"
                      placeholder="Selecione a Fonte Recurso"
                      valueRenderer={this.renderValue}
                    />
                  </Col>
                </Row>
              </Panel>
            </div>
            <Col xs={12} sm={4} md={4}>
              <div className="form-group">
                <label className="label"></label>
                <div className="btn-group">
                  <button
                    id="btnFiltrar"
                    className="btn module-color icon-right"
                    type="submit"
                    ref="pesquisar"
                    onClick={this.consultarRestosAPagar}
                  >
                    Pesquisar
                    <em className="fa fa-search"></em>
                  </button>
                  <button
                    id="btnResetFilter"
                    className="btn neutral icon-right inline no-text"
                    type="button"
                    onClick={this.onResetFiltro}
                  >
                    Limpar
                    <em className="fa fa-times"></em>
                  </button>
                </div>
              </div>
            </Col>
          </form>
        </Panel>

        <Panel isForm tableResponsive expansible={false}>
          <Row>
            <Col xs={4}>
              <UltimaAtualizacaoByTable
                modulo="3"
                table="empenho"
                column="dataultimaatualizacao"
              />
            </Col>
            <Col xs={8}>
              <ExportButtonGroup onClick={this.onExporta} />
            </Col>
          </Row>
          <Tabs ref="tab">
            <Tab
              title="Processados"
              name="tabProcessados"
              ref="tabProcessados"
              onlyTable
              active
            >
              {pageProcessados && pageProcessados.get('content') && (
                <div className="panel-table-outer">
                  <table className="panel-table striped fancy">
                    <TableHeader
                      empty={pageProcessados.get('content').isEmpty()}
                      text="Não foram encontrados registros."
                    >
                      <tr>
                        <TableHeaderItem
                          field="empenho"
                          label="Empenho"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                        />
                        <TableHeaderItem
                          field="dataEmissao"
                          label="Data"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                        />
                        <TableHeaderItem
                          field="descricaoFonte"
                          label="Fonte de Recursos"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                        />
                        <TableHeaderItem
                          field="nome"
                          label="Fornecedor"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                        />
                        <TableHeaderItem
                          field="proc"
                          label="Valor Inscrito"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <TableHeaderItem
                          field="canceladoProc"
                          label="Valor Cancelado"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <TableHeaderItem
                          field="pagoProc"
                          label="Valor Pago"
                          page={pageProcessados}
                          searchWithPage={this.searchWithPageProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <th />
                      </tr>
                    </TableHeader>
                    <tbody>
                      {pageProcessados
                        .get('content')
                        .map(this.renderRestosProcessados)}
                    </tbody>
                  </table>
                </div>
              )}
              <SearchPagination
                page={pageProcessados}
                searchWithPage={this.searchWithPageProcessados}
              />
            </Tab>
            <Tab
              title="Não Processados"
              name="tabNaoProcessados"
              ref="tabNaoProcessados"
              onlyTable
            >
              {pageNaoProcessados && pageNaoProcessados.get('content') && (
                <div className="panel-table-outer">
                  <table className="panel-table striped fancy">
                    <TableHeader
                      empty={pageNaoProcessados.get('content').isEmpty()}
                      text="Não foram encontrados registros."
                    >
                      <tr>
                        <TableHeaderItem
                          field="empenho"
                          label="Empenho"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                        />
                        <TableHeaderItem
                          field="dataEmissao"
                          label="Data"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                        />
                        <TableHeaderItem
                          field="descricaoFonte"
                          label="Fonte de Recursos"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                        />
                        <TableHeaderItem
                          field="nome"
                          label="Fornecedor"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                        />
                        <TableHeaderItem
                          field="aproc"
                          label="Valor Inscrito"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <TableHeaderItem
                          field="liquidado"
                          label="Valor Liquidado"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <TableHeaderItem
                          field="canceladoAProc"
                          label="Valor Cancelado"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <TableHeaderItem
                          field="pagoAProc"
                          label="Valor Pago"
                          page={pageNaoProcessados}
                          searchWithPage={this.searchWithPageNaoProcessados}
                          alignment="right"
                          sortable={!isIntegradoOxy}
                        />
                        <th />
                      </tr>
                    </TableHeader>
                    <tbody>
                      {pageNaoProcessados
                        .get('content')
                        .map(this.renderRestosNaoProcessados)}
                    </tbody>
                  </table>
                </div>
              )}
              <SearchPagination
                page={pageNaoProcessados}
                searchWithPage={this.searchWithPageNaoProcessados}
              />
            </Tab>
          </Tabs>
        </Panel>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const tipoPesquisa = fromJS(['Todos', 'Processados', 'Não Processados']);

  return {
    filtros: state.crud.getIn(['filtroRestosAPagar', 'currentRecord'], Map({})),
    pageProcessados: state.restosAPagar.get('restosAPagarProcessados'),
    pageNaoProcessados: state.restosAPagar.get('restosAPagarNaoProcessados'),
    tipoPesquisa: tipoPesquisa,
    isIntegradoOxy: state.entidade.getIn([
      'configuracoesGerais',
      'isIntegradoOxy'
    ])
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormRestosAPagar);
