export const PAGAR_LIST = 'PAGAR_LIST';
export const PAGAR_LIST_START = 'PAGAR_LIST_START';
export const PAGAR_LIST_SUCCESS = 'PAGAR_LIST_SUCCESS';
export const PAGAR_LIST_ERROR = 'PAGAR_LIST_ERROR';

export const TOTAL_PAGAR = 'TOTAL_PAGAR';
export const TOTAL_PAGAR_START = 'TOTAL_PAGAR_START';
export const TOTAL_PAGAR_SUCCESS = 'TOTAL_PAGAR_SUCCESS';
export const TOTAL_PAGAR_ERROR = 'TOTAL_PAGAR_ERROR';

export const PAGAS_LIST = 'PAGAS_LIST';
export const PAGAS_LIST_START = 'PAGAS_LIST_START';
export const PAGAS_LIST_SUCCESS = 'PAGAS_LIST_SUCCESS';
export const PAGAS_LIST_ERROR = 'PAGAS_LIST_ERROR';

export const TOTAL_PAGAS = 'TOTAL_PAGAS';
export const TOTAL_PAGAS_START = 'TOTAL_PAGAS_START';
export const TOTAL_PAGAS_SUCCESS = 'TOTAL_PAGAS_SUCCESS';
export const TOTAL_PAGAS_ERROR = 'TOTAL_PAGAS_ERROR';

export const RESET_CONSULTA_APTOPAGAMENTO = 'RESET_CONSULTA_APTOPAGAMENTO';

export const PROGRAMATICAORGAO = 'PROGRAMATICAORGAO';
export const PROGRAMATICAORGAO_START = 'PROGRAMATICAORGAO_START';
export const PROGRAMATICAORGAO_SUCCESS = 'PROGRAMATICAORGAO_SUCCESS';
export const PROGRAMATICAORGAO_ERROR = 'PROGRAMATICAORGAO_ERROR';

export const PROGRAMATICAUNIDADE = 'PROGRAMATICAUNIDADE';
export const PROGRAMATICAUNIDADE_START = 'PROGRAMATICAUNIDADE_';
export const PROGRAMATICAUNIDADE_SUCCESS = 'PROGRAMATICAUNIDADE_SUCCESS';
export const PROGRAMATICAUNIDADE_ERROR = 'PROGRAMATICAUNIDADE_ERROR';

export const PROGRAMATICAFUNCAO = 'PROGRAMATICAFUNCAO';
export const PROGRAMATICAFUNCAO_START = 'PROGRAMATICAFUNCAO_START';
export const PROGRAMATICAFUNCAO_SUCCESS = 'PROGRAMATICAFUNCAO_SUCCESS';
export const PROGRAMATICAFUNCAO_ERROR = 'PROGRAMATICAFUNCAO_ERROR';

export const PROGRAMATICASUBFUNCAO = 'PROGRAMATICASUBFUNCAO';
export const PROGRAMATICASUBFUNCAO_START = 'PROGRAMATICASUBFUNCAO_START';
export const PROGRAMATICASUBFUNCAO_SUCCESS = 'PROGRAMATICASUBFUNCAO_SUCCESS';
export const PROGRAMATICASUBFUNCAO_ERROR = 'PROGRAMATICASUBFUNCAO_ERROR';

export const PROGRAMATICAPROGRAMA = 'PROGRAMATICAPROGRAMA';
export const PROGRAMATICAPROGRAMA_START = 'PROGRAMATICAPROGRAMA_START';
export const PROGRAMATICAPROGRAMA_SUCCESS = 'PROGRAMATICAPROGRAMA_SUCCESS';
export const PROGRAMATICAPROGRAMA_ERROR = 'PROGRAMATICAPROGRAMA_ERROR';

export const PROGRAMATICAPROJETO = 'PROGRAMATICAPROJETO';
export const PROGRAMATICAPROJETO_START = 'PROGRAMATICAPROJETO_START';
export const PROGRAMATICAPROJETO_SUCCESS = 'PROGRAMATICAPROJETO_SUCCESS';
export const PROGRAMATICAPROJETO_ERROR = 'PROGRAMATICAPROJETO_ERROR';

export const PROGRAMATICAELEMENTO = 'PROGRAMATICAELEMENTO';
export const PROGRAMATICAELEMENTO_START = 'PROGRAMATICAELEMENTO_START';
export const PROGRAMATICAELEMENTO_SUCCESS = 'PROGRAMATICAELEMENTO_SUCCESS';
export const PROGRAMATICAELEMENTO_ERROR = 'PROGRAMATICAELEMENTO_ERROR';

export const PROGRAMATICADESDOBRAMENTO = 'PROGRAMATICADESDOBRAMENTO';
export const PROGRAMATICADESDOBRAMENTO_START =
  'PROGRAMATICADESDOBRAMENTO_START';
export const PROGRAMATICADESDOBRAMENTO_SUCCESS =
  'PROGRAMATICADESDOBRAMENTO_SUCCESS';
export const PROGRAMATICADESDOBRAMENTO_ERROR =
  'PROGRAMATICADESDOBRAMENTO_ERROR';

export const PROGRAMATICASUBDESDOBRAMENTO = 'PROGRAMATICASUBDESDOBRAMENTO';
export const PROGRAMATICASUBDESDOBRAMENTO_START =
  'PROGRAMATICASUBDESDOBRAMENTO_START';
export const PROGRAMATICASUBDESDOBRAMENTO_SUCCESS =
  'PROGRAMATICASUBDESDOBRAMENTO_SUCCESS';
export const PROGRAMATICASUBDESDOBRAMENTO_ERROR =
  'PROGRAMATICASUBDESDOBRAMENTO_ERROR';

export const PROGRAMATICAFONTERECURSO = 'PROGRAMATICAFONTERECURSO';
export const PROGRAMATICAFONTERECURSO_START = 'PROGRAMATICAFONTERECURSO_START';
export const PROGRAMATICAFONTERECURSO_SUCCESS =
  'PROGRAMATICAFONTERECURSO_SUCCESS';
export const PROGRAMATICAFONTERECURSO_ERROR = 'PROGRAMATICAFONTERECURSO_ERROR';
