import * as types from './Types.js';

export function resetEmpenho() {
  return {
    type: types.RESET_EMPENHO
  };
}

export function saveFiltroListagem(filtro) {
  return {
    type: types.FILTROLISTAGEM_EMPENHO,
    payload: filtro
  };
}

export function findEmpenhos(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHOS,
    payload: {
      promise: fetch(`/empenhos/lista?${filtro}`)
    }
  });
}

export function findEmpenhosSemExercicio(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHOS_SEM_EXERCICIO,
    payload: {
      promise: fetch(`/empenhos/lista/sem-exercicio?${filtro}`)
    }
  });
}

export function findEmpenhosTotais(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHOSTOTAIS,
    payload: {
      promise: fetch(`/empenhos/totais?${filtro}`)
    }
  });
}

export function findEmpenhoDetalhe(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHE,
    payload: {
      promise: fetch(`/empenhos/detalhe?${filtro}`)
    }
  });
}

export function findEmpenhoItens(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEITENS,
    payload: {
      promise: fetch(`/empenhos/detalhe/itens?${filtro}`)
    }
  });
}

export function findEmpenhoAnulacoes(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEANULACOES,
    payload: {
      promise: fetch(`/empenhos/detalhe/anulacoes?${filtro}`)
    }
  });
}

export function findEmpenhoLiquidacoes(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHELIQUIDACOES,
    payload: {
      promise: fetch(`/empenhos/detalhe/liquidacoes?${filtro}`)
    }
  });
}

export function findEmpenhoLiquidacoesMultipla(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHELIQUIDACOES_MULTIPLAS,
    payload: {
      promise: fetch(`/empenhos/detalhe/liquidacoes?${filtro}`)
    }
  });
}

export function resetLiquidacoesMultipla() {
  return () => ({
    type: types.EMPENHODETALHELIQUIDACOES_RESET,
    payload: {
      promise: Promise.resolve([])
    }
  });
}

export function findEmpenhoRetencoes(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHERETENCOES,
    payload: {
      promise: fetch(`/empenhos/detalhe/retencoes?${filtro}`)
    }
  });
}

export function findEmpenhoLiquidacoesDocumentos(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHELIQUIDACOESDOCUMENTOS,
    payload: {
      promise: fetch(`/empenhos/detalhe/liquidacoesdocumentos?${filtro}`)
    }
  });
}

export function findEmpenhoLiquidacoesDocumentosMultiplos(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_MULTIPLO,
    payload: {
      promise: fetch(`/empenhos/detalhe/liquidacoesdocumentos?${filtro}`)
    }
  });
}

export function resetLiquidacoesDocumentosMultiplos() {
  return () => ({
    type: types.EMPENHODETALHELIQUIDACOESDOCUMENTOS_RESET,
    payload: {
      promise: Promise.resolve([])
    }
  });
}

export function findEmpenhoAnexos(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEANEXOS,
    payload: {
      promise: fetch(`/empenhos/detalhe/anexos?${filtro}`)
    }
  });
}

export function findEmpenhoPagamentos(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEPAGAMENTOS,
    payload: {
      promise: fetch(`/empenhos/detalhe/pagamentos?${filtro}`)
    }
  });
}

export function findEmpenhoMovimentacao(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEMOVIMENTACAO,
    payload: {
      promise: fetch(`/empenhos/detalhe/movimentacao?${filtro}`)
    }
  });
}

export function findOrgao() {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAORGAO,
    payload: {
      promise: fetch('/api/programatica/orgao')
    }
  });
}

export function findUnidade(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAUNIDADE,
    payload: fetch(`/api/programatica/unidade?${filtro}`)
  });
}

export function findFuncao(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAFUNCAO,
    payload: {
      promise: fetch(`/api/programatica/funcao?${filtro}`)
    }
  });
}

export function findSubFuncao(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICASUBFUNCAO,
    payload: {
      promise: fetch(`/api/programatica/subFuncao?${filtro}`)
    }
  });
}

export function findPrograma(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAPROGRAMA,
    payload: {
      promise: fetch(`/api/programatica/programa?${filtro}`)
    }
  });
}

export function findProjeto(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAPROJETO,
    payload: {
      promise: fetch(`/api/programatica/projeto?${filtro}`)
    }
  });
}

export function findElemento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAELEMENTO,
    payload: {
      promise: fetch(`/api/programatica/elemento?${filtro}`)
    }
  });
}

export function findDesdobramento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICADESDOBRAMENTO,
    payload: {
      promise: fetch(`/api/programatica/desdobramento?${filtro}`)
    }
  });
}

export function findSubDesdobramento(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICASUBDESDOBRAMENTO,
    payload: {
      promise: fetch(`/api/programatica/subDesdobramento?${filtro}`)
    }
  });
}

export function findFonteRecurso(filtro) {
  return ({ fetch }) => ({
    type: types.PROGRAMATICAFONTERECURSO,
    payload: {
      promise: fetch(`/api/programatica/fonteRecurso?${filtro}`)
    }
  });
}

export function findEmpenhoItemDetalhe(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHEITEM,
    payload: {
      promise: fetch(`/empenhos/detalhe/item?${filtro}`)
    }
  });
}

export function findEmpenhoEmLiquidacao(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHO_DETALHE_EM_LIQUIDACAO,
    payload: {
      promise: fetch(`/empenhos/detalhe/em-liquidacao?${filtro}`)
    }
  });
}

export function findNotaEmpenho(filtro) {
  return ({ fetch }) => ({
    type: types.NOTAEMPENHO,
    payload: {
      promise: fetch(`/empenhos/nota-empenho?${filtro}`)
    }
  });
}

export function findEmpenhoLinks(filtro) {
  return ({ fetch }) => ({
    type: types.EMPENHODETALHELINKS,
    payload: {
      promise: fetch(`/empenhos/detalhe/links?${filtro}`)
    }
  });
}
